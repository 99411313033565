<template>
  <b-card >

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="7"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.data[0].image"
            :text="avatarText(userData.data[0].fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.data[0].fullName }}
              </h4>
              <span class="card-text">{{ userData.data[0].email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.data[0]._id } }"
                variant="primary"
              >
                Modifier
              </b-button>
              <span v-if="userData.data[0].proRequests!==null && userData.data[0].proRequests.length>0">
              <b-button
                v-if="userData.data[0].isVerified===false"
                @click="verified()"
                variant="outline-danger"
                class="ml-1"
              >
                vérifier
              </b-button>
              <b-button
                @click="verified()"
                v-else
                variant="outline-danger"
                class="ml-1"
              >
                Non vérifier
              </b-button>
              </span>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
               {{userData.data[0].boostRequests.length}}
              </h5>
              <small>Boost Requests</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{userData.data[0].likes.length}}
              </h5>
              <small>Likes</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="5"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Identifiant</span>
            </th>
            <td class="pb-50">
              {{ userData.data[0].identifier }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Statut</span>
            </th>
            <td v-if="userData.data[0].isVerified==true" class="pb-50 text-capitalize">
              Vérifier
            </td>
            <td v-else class="pb-50 text-capitalize">
              Not vérifier
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Rôle</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.data[0].role }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Cité</span>
            </th>
            <td class="pb-50">
              {{ userData.data[0].city }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.data[0].phoneNumber }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  methods : {
    verified() {
      const {  updateUser } = useUsersList()
       this.userData.data[0].isVerified = !this.userData.data[0].isVerified
       updateUser(this.userData.data[0])
    }
  },
 
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      useUsersList,
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
