<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>


     <b-card-actions action-collapse title="Liste des produits">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
      :rows="userData.data[0].posts.all.data"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >

  <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.label === 'Comments'"
          class="text-nowrap"
        >

         <app-collapse>
            <app-collapse-item :title="'All Comments : '+props.row.comments.length">
              <app-collapse>
                <app-collapse-item v-for="comment in props.row.comments" :key="comment._id" title="sender">
                  <span style="float:left; font-size: 13px;">{{comment.content}}</span><span style="float:right; font-size: 13px">{{comment.createdAt | moment("YYYY-MM-DD:HH:mm")}}</span><br>
                 <feather-icon  icon="TrashIcon" style="float:right" @click="_deleteComment(props.row,comment)" />
                  <span v-for="replie in comment.replies" :key="replie._id">
                  <div style="margin-left:10px">
                  <span> sender </span><br>
                  <span style="float:left; font-size: 13px">{{replie.content}}</span> <span style="float:right; font-size: 13px">{{ replie.createdAt | moment("YYYY-MM-DD:HH:mm") }}</span><br>
                  <feather-icon  icon="TrashIcon" style="float:right" @click="_deleteReplie(props.row,comment,replie)" />
                  </div>
                  </span>
                </app-collapse-item>  
              </app-collapse>
           </app-collapse-item>
         </app-collapse>
        </span>
         <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  @click="_deletePost(props.row)">
                <feather-icon
                 
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

  </template>
    <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>

  
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BDropdown, BDropdownItem,
    BCard,
  BCardHeader,
  BCardBody,

  BFormInput,
  BButton,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import 'vue-good-table/dist/vue-good-table.css'

import { VueGoodTable } from 'vue-good-table';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import useUsersList from '../users-list/useUsersList'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  
  components: {
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    BDropdown, 
    BDropdownItem,
    BRow,
    BCol,
    BAlert,
    BLink,
BCardActions,
    BCard,
  BCardHeader,
  BCardBody,

  BFormInput,
  BButton,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,

    InvoiceList,
  },
  data(){
    return {
                 pageLength: 5,
      searchTerm: "",
      columns: [
        /*{
          label: 'id Post',
          field: '_id',
        },*/
        {
          label: 'Product',
          field: 'product.name',
        },
        {
          label: 'Likes',
          field: 'likes.length',
          type: 'number',
        },
        {
          label: 'Comments',
          field: 'comments.length'
        },
        {
          label: 'Action',
          field: 'action',
        },
        
      ],
       user: {}
    };
  },
  mounted() {
    
    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { this.user = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
  },
  methods : {
      advanceSearch(val) {
      this.searchTerm = val;
    },
    _deleteComment(post,comment) {
      /*for(let j=0 ; j < this.user.data[0].posts.all.data.length; j++) {
        if(this.user.data[0].posts.all.data[j]._id==post._id) {
            for(let i = 0 ; i < this.user.data[0].posts.all.data[j].comments.length; i++) {
              if(this.user.data[0].posts.all.data[j].comments[i]._id===comment._id) {
              this.user.data[0].posts.all.data[j].comments.splice(i,1)
              break;
              }
            }
        }
      }*/
       
       /*const {  updateUser } = useUsersList()
       item.deactivated = !item.deactivated
       updateUser(item)*/

       
        axios
          .delete('http://102.219.178.151/backend/api/posts/'+post._id+'/comments/'+comment._id)
          .then(response => {
             if(response.data.path==="_id") {
                 this.$toast({
                  component: ToastificationContent,
                props: {
                  title: 'Comment not deleted!',
                  icon: 'TrachIcon',
                  variant: 'danger',
                },
              })
             }else{
               location.reload()
             }
          })
      
    },
    _deleteReplie(post,comment,replie) {
       axios
          .delete('http://102.219.178.151/backend/api/posts/'+post._id+'/comments/'+comment._id+'/replies/'+replie._id)
          .then(response => {
             if(response.data.path==="_id") {
                 this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Replie comment not deleted!',
                  icon: 'TrachIcon',
                  variant: 'danger',
                },
              })
             }else{
               location.reload()
             }
          })
    },

    _deletePost(post) {
       axios
          .delete('http://102.219.178.151/backend/api/posts/'+post._id)
          .then(response => {
            console.log("delete post ",response)
             if(response.data.path==="_id") {
                 this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Post not deleted!',
                  icon: 'TrachIcon',
                  variant: 'danger',
                },
              })
             }else{
               location.reload()
             }
          })
    }
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
     
useUsersList
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
