import axios from '@axios'

export default {
  namespaced: true,
  state: {
    users : []
  },
  getters: {
    getCurrentUsers(state) {
      return state.users
  },
  },
  mutations: {
    setCurrentUsers(state, users) {
      state.users = users
  },
  },
  actions: {
     fetchUsers(context, queryParams) {
      console.log("test query ",queryParams)
      return new Promise((resolve, reject) => {
        let users = context.getters.getCurrentUsers
        console.log("test users ", users)
        let tab = []
        let resh=false
        if (users.length > 0) {
          if (queryParams.role != null) {
            for (let i = 0; i < users.length; i++) {
              if (users[i].role == queryParams.role) {
                tab.push(users[i])
              }
            }
          } else {
            tab = users
          }
          // test is verified
          let tabaux = []
          if (queryParams.plan != null) {
            
            let isV = ''
            if (queryParams.plan === "true")
              isV = true
            else
              isV = false
            for (let i = 0; i < tab.length; i++) {
              if (tab[i].isVerified == isV) {
                tabaux.push(tab[i])
              }
            }
            tab = tabaux
          }

          if (queryParams.status != null) {
            tabaux = []
            console.log("my tab ",tab.length,tabaux.length)
            let isD = ''
            if (queryParams.status === "true")
              isD = true
            else
              isD = false
            for (let i = 0; i < tab.length; i++) {
              if (tab[i].deactivated == isD) {
                tabaux.push(tab[i])
              }
            }
            tab = tabaux
          }
         
          if (queryParams.q !== "") {
            resh = true
            let tab1 = tab.filter(m =>
              m.fullName.toLowerCase().includes(queryParams.q.toLowerCase())
            );

            let tab2 = tab.filter(v =>
              v.role.toLowerCase().includes(queryParams.q.toLowerCase())
            );


            let tab3 = tab.filter(v =>
              v.email.toLowerCase().includes(queryParams.q.toLowerCase())
            );
          
            let exist, k, x;
            tab = []
            for (x = 0; x < tab1.length; x++) {
              exist = false
              for (k = 0; k < tab.length; k++) {
                if (tab[k]._id == tab1[x]._id) {
                  exist = true
                  break
                }
              }
              if (!exist) {
                tab.push(tab1[x])
              }
            }
            for (x = 0; x < tab2.length; x++) {
              exist = false
              for (k = 0; k < tab.length; k++) {
                if (tab[k]._id == tab2[x]._id) {
                  exist = true
                  break
                }
              }
              if (!exist) {
                tab.push(tab2[x])
              }
            }

            for (x = 0; x < tab3.length; x++) {
              exist = false
              for (k = 0; k < tab.length; k++) {
                if (tab[k]._id == tab3[x]._id) {
                  exist = true
                  break
                }
              }
              if (!exist) {
                tab.push(tab3[x])
              }
            }

            let res = tab.length
            var tab5 = []
            let fin;
            if (queryParams.page * queryParams.perPage > tab.length)
              fin = tab.length
            else fin = queryParams.page * queryParams.perPage
            for (let cpt = (queryParams.page - 1) * queryParams.perPage; cpt < fin; cpt++) {
              if (tab[cpt] !== undefined)
                tab5.push(tab[cpt])
            }
            let result = {}
            result.data = tab5
            result.count = res
            resolve(result)
          }
          if(resh==false) {
          let res1 = tab.length

          var tab6 = []
          let fin1;
          if (queryParams.page * queryParams.perPage > tab.length)
            fin1 = tab.length
          else fin1 = queryParams.page * queryParams.perPage
          for (let cpt = (queryParams.page - 1) * queryParams.perPage; cpt < fin1; cpt++) {
            if (tab[cpt] !== undefined)
              tab6.push(tab[cpt])
          }
  
              
          let result1 = {}
          result1.data = tab6
          result1.count = res1
          resolve(result1)
        }
          
        }else{
        axios
          .get('http://102.219.178.151/backend/api/users')
          .then(response => {

           
            /*for (let j = 0; j < 27; j++) {
              let cp = Object.assign({}, response.data.data[0]);
              cp._id = j+1
              response.data.data.push(cp)
            }*/
            
              response.data.data.forEach(async (c) => {
                await axios
                .get('http://102.219.178.151/backend/api/users/'+c._id+'/pro')
                  .then(response => {
                      c.proRequests = response.data.data
                })
              });
            

            context.commit('setCurrentUsers', response.data.data)

            response.data.count = response.data.data.length
            var tab6 = []
            let fin1 ;
            if (queryParams.page * queryParams.perPage > response.data.data.length)
                fin1 = response.data.data.length
            else
                fin1 = queryParams.page * queryParams.perPage
              for(let b = (queryParams.page-1)*queryParams.perPage ; b < fin1 ; b++) {
                if(response.data.data[b]!==undefined)
                  tab6.push(response.data.data[b])
              }
  
            response.data.data = []
            response.data.data = tab6

            resolve(response.data)
           }
          )
          .catch(error => reject(error)) 
      }
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('http://102.219.178.151/backend/api/users/'+userData.item._id,userData.item)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('http://102.219.178.151/backend/api/users/?id=' + id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
