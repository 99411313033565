<template>
  <b-card
    no-body
    class="user-card-size"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Autres informations
      </h5>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span v-if="userData.data[0].followers!==undefined" class="align-middle">{{userData.data[0].followers.count}} Follower</span>
          <span v-else class="align-middle">0 Follower</span>
        </li>
        <li class="my-25">
          <span v-if="userData.data[0].followings!==undefined" class="align-middle">{{userData.data[0].followings.count}}  Following</span>
          <span v-else class="align-middle">0  Following</span>
        </li>
        <li class="my-25">
          <span v-if="userData.data[0].posts!==undefined"  class="align-middle">{{userData.data[0].posts.all.count}}  Postes</span>
          <span v-else class="align-middle">0  Postes</span>
        </li>
        <li class="my-25">
          <span v-if="userData.data[0].boostRequests!==undefined" class="align-middle">{{userData.data[0].boostRequests.length}} Boost</span>
          <span v-else class="align-middle">0 Boost</span>
        </li>
         <li>
          <span v-if="userData.data[0].likes!==undefined" class="align-middle">{{userData.data[0].likes.length}} J'aimes</span>
          <span v-else class="align-middle">0 J'aimes</span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useUsersList from '../users-list/useUsersList'

export default {
  directives: {
    Ripple,
  },
   props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    activated() {
       const {  updateUser } = useUsersList()
       this.userData.data[0].deactivated = !this.userData.data[0].deactivated
       updateUser(this.userData.data[0])
    }
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
}
</script>

<style>

</style>
